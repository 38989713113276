import {
  FormControl,
  Select,
  InputBase,
  styled,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "helpers/helpers";

interface Props {
  value: string;
  onChange: any;
  icon: any;
  children: any;
}

export default function SelectorWithIcon({
  value,
  onChange,
  icon,
  children,
}: Props) {
  return (
    <FormControl>
      <Select
        value={value}
        input={
          <CustomInput
            startAdornment={
              <InputAdornment position="start">
                <FontAwesomeIcon icon={icon} color="black" />
              </InputAdornment>
            }
          />
        }
        onChange={onChange}
      >
        {children}
      </Select>
    </FormControl>
  );
}

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    border: `0.5px solid #adadad42`,
    "&:hover": {
      borderColor: "#5c037c",
    },
    fontSize: 16,
    padding: "9px 5px 9px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 10,
      borderColor: "#6F23FF",
      boxShadow: "0 0 0 0.2rem rgba(98,85,164,.25)",
    },
  },
}));
